<template>
  <div class="faq-page">
    <page-header heading="How can we help you?" desc="A quick way to know about KIPS virtual" />

    <section class="repeatFaq">
      <div class="container general-faq-cntnr">
        <div class="faq-generic-title mb-3">General</div>
        <div class="row">
          <div class="col-md-4">
            <div class="faq-section-heading">What is KIPS VIRTUAL?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">KIPS Virtual is an online facility for students of classes 9, 10, 1st year, 2nd year, and entry test preparation, where they can purchase courses and learn online through video lectures, readings, tests, and teacher support.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">Which browser is recommended for using KIPS VIRTUAL?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">The updated version of Google Chrome is recommended for KIPS Virtual. However, Microsoft Edge, Mozilla FireFox and Safari also support KIPS Virtual.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">Which mobile OS version is recommended for using KIPS VIRTUAL App?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">Android devices need to have 6.0 or above and IOS devices need to have 11.0 or above for a hassle-free experience.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">Are there any specific requirements of Internet Connection for KIPS VIRTUAL?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">High speed uninterrupted internet connection is required for attempting tests, watching videos and readings, and using all other features without any hindrance. (3G connection or above is recommended for mobile users)</div>
          </div>
        </div>
      </div>
    </section>

    <section class="repeatFaq">
      <div class="container registration-faq-cntnr">
        <div class="faq-generic-title mb-3">KIPS Virtual Accounts</div>
        <div class="row">
          <div class="col-md-4">
            <div class="faq-section-heading">What do I do if I forget my student account password?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">In case you forget your password, click the "Forgot Password" link on the login page. After entering your username, your new password will be sent to your registered contact number.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">How do I recover my student account in case I forget my username?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">Send an Email to <a href="mailto:support@kipsvirtual.com">support@kipsvirtual.com</a>. Your credentials (username and password) will be sent to your registered contact number.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">How long does my student account remain valid?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">Once you have signed up, your account remains valid for as long as you wish to keep it.</div>
          </div>
        </div>
      </div>
    </section>

    <section class="repeatFaq">
      <div class="container howto-faq-cntnr">
        <div class="faq-generic-title mb-3">How To's</div>
        <div class="row">
          <div class="col-md-4">
            <div class="faq-section-heading">How do I attempt tests, watch videos, and view readings?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">When you click on a subject's tab on "My Programs" page, you will find readings, videos, tests,and assignments. Alternatively, you can search for your desired videos and readings.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">How do I report a mistake I find in a question, its answer, or in readings and videos etc.?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">You can report a mistake by clicking on the "Report" icon in videos, readings, tests, and assignments. Alternatively, you may share the details of the mistake through teacher support, or through email to <a href="mailto:support@kipsvirtual.com">support@kipsvirtual.com</a></div>
          </div>
        </div>
      </div>
    </section>

    <section class="repeatFaq">
      <div class="container support-faq-cntnr">
        <div class="faq-generic-title mb-3">Support</div>
        <div class="row">
          <div class="col-md-4">
            <div class="faq-section-heading">What is teacher support?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">This feature of KIPS Virtual enables you to chat with subject experts to find instant answers to your queries.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">How do I access teacher support?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">You can access chat from the Subject page or through the Teacher Support link in the left menu.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">At what times is teacher support available?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">Teacher Support is available on working days between 9 am and 9 pm.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">How do I contact KIPS Virtual support team?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">Send an email to <a href="mailto:support@kipsvirtual.com">support@kipsvirtual.com</a>. Your queries will be catered within 48 hours.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">How can I view my progress in my programs?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">You will find a 'Stats' link in the left menu. From there, you can view a graphical representation of the extent to which you have completed the course of each subject in each of your programs, along with your result in each test.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">How can I know where I stand in my current session in comparison to other LMS users?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">You will find a 'Leaderboard' link in the left menu. From there, you can view the top 20 positions among the students of your program.</div>
          </div>
        </div>
      </div>
    </section>

    <section class="repeatFaq">
      <div class="container lms-session-faq-cntnr">
        <div class="faq-generic-title mb-3">Registration & Sessions (Campus Students Only)</div>
        <div class="row">
          <div class="col-md-4">
            <div class="faq-section-heading">How do I get registered for KIPS Campus Sessions?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">To get a student account, you need to get yourself registered at a KIPS Campus or visit <a href="https://kips.edu.pk/" target="_blank">kips.edu.pk</a>. Please note that all sessions are not available on KIPS VIRTUAL. For details, please contact the relevant Campus Manager.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">How do I get Username and Password for the student account?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">If your session is available on KIPS VIRTUAL, your username and password will be sent within 48 hours to the contact number you provided when getting registered.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">Which sessions are available on KIPS Virtual?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">Contact your Campus manager for information regarding the availability of a session. Not all sessions conducted in campuses are available on KIPS Virtual.</div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">What do I do if I get registered to the wrong program? How do I change the registered program?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">You need to provide all the details to the campus manager, after whose approval, your program will be changed accordingly.</div>
          </div>
        </div>
      </div>
    </section>
    <appFooter />

    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
import footer from "../layout/landing/appFooter.vue";
import pageHeader from "../components/pageHeader.vue";

export default {
  components: {
    appFooter: footer,
    pageHeader,
  },
  metaInfo: {
    title: "FAQ | KIPS VIRTUAL",
    // meta: [
    //   {
    //     name: "description",
    //     content:
    //       "Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.",
    //   },
    //   {
    //     property: "og:title",
    //     content: "Epiloge - Build your network in your field of interest",
    //   },
    // ],
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  computed: {},
  asyncData({ store, route }) {},
  created() {},
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>
