<template>
 <section class="course-cateory-section programs-offer">
    <div class="category-cntr">
      <component :is="name"></component>
      <div class="circle-pattern">
        <img src="/assets/images/banner/circle-pattern.svg " alt="" />
      </div>
    </div>
    <div class="circle-pattern-2">
      <img src="/assets/images/banner/circle-pattern-2.svg " alt="" />
    </div>
  </section>

</template>

<script>
// import { mapGetters, mapActions } from "vuex";
// import {
//   board as boardStoreKeys,
//   course as courseStoreKeys,
//   classes as classesStoreKeys,
// } from "../../../store/keys";
// import { appConstants } from "../../../common/constants";
// import breadcrumb from "../breadcrumb.vue";
export default {
  components: {
    // breadcrumb,
    //"wizard-step1": () => import("./wizard-step1"),

    "course-categories": () => import("./courseCategory"),
    // "wizard-step3": () => import("./wizard-step3"),
    // "wizard-step4": () => import("./wizard-step4"),
  },
  data() {
    return {
      name: "course-categories"
      //   isNextButtonDisabled: true,
      //   boardList: [],
      //   selectedClassBoard: null,
    };
  },
  mounted() {
    alert(this.id)
  }
  // Client-side only
  //   mounted() {
  //     this.getClassBoards();
  //   },
  //   destroyed() {
  //     // this.resetBoards();
  //   },
  //   watch: {
  //     $route(to, from) {
  //       if (process.env.VUE_ENV === "client") {
  //         this.getClassBoards();
  //       }
  //     },
  //   },
  //   computed: {
  //     ...mapGetters(boardStoreKeys.namespace, boardStoreKeys.getters),
  //     ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
  //     ...mapGetters(classesStoreKeys.namespace, classesStoreKeys.getters),
  //   },
  //   // Server-side only
  //   serverPrefetch() {},
  //   methods: {
  //     ...mapActions(boardStoreKeys.namespace, boardStoreKeys.actions),
  //     ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),

  //     getClassBoards() {
  //       this.isNextButtonDisabled = true;
  //       return this.get_ClassBoards({
  //         data: {
  //           ProgramId: this.selectedProgram.id,
  //         },
  //         config: {
  //           loader: {
  //             show: true,
  //             id:"boardLoader"
  //           },
  //         },
  //       })
  //         .then((response) => {
  //           this.boardList = response.Boards;
  //           this.updateSelectedClassBoard(
  //             this.selectedCourseInfo.board.id === 0
  //               ? response.Boards[0]
  //               : response.Boards.find((board) => {
  //                   return board.BoardId === this.selectedCourseInfo.board.id;
  //                 })
  //           );

  //           this.update_SelectedClassBoard({
  //             data: {
  //               id: 0,
  //               name: "",
  //             },
  //           });

  //           this.update_SelectedCourseGroup({
  //             data: {
  //               id: 0,
  //               name: "",
  //             },
  //           });
  //         })
  //         .catch((ex) => {
  //           this.$alertError(ex.message);
  //         });
  //     },
  //     updateSelectedClassBoard(board) {
  //       this.selectedClassBoard = board;
  //       this.isNextButtonDisabled = false;
  //     },
  //     UpdateNextWizard() {
  //       this.update_SelectedClassBoard({
  //         data: {
  //           id: this.selectedClassBoard.BoardId,
  //           name: this.selectedClassBoard.Name,
  //         },
  //         config: {
  //           loader: {
  //             show: false,
  //           },
  //         },
  //       })
  //         .then((response) => {
  //           this.$emit("onUpdateWizard", "wizard-step3");
  //         })
  //         .catch((ex) => {
  //           this.$alertError(ex.message);
  //         });
  //     },
  //   },
  // };
};
</script>
