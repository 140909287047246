<template>
  <div>
    <h1 style="margin-top:100px">counter: {{ getCounter }}</h1>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { demoCourses as demoCoursesStoreKeys } from "../store/keys";
export default {
  computed: {
    ...mapGetters(demoCoursesStoreKeys.namespace, demoCoursesStoreKeys.getters),
  },
  methods: {
    ...mapActions(demoCoursesStoreKeys.namespace, demoCoursesStoreKeys.actions),
  },
  mounted() {
    debugger
    this.increase_Counter()
  },
  async serverPrefetch() {
    return this.increase_Counter()
  },
};
</script>
